<template>
  <div>
    <b-modal size="sm" id="addNode" centered hide-footer hide-header>
      <h5 class="text-blue font-weight-bold">{{ "Add Node" }}</h5>

      <div class="row">
        <div class="col">

          <div class="col mb-3">
            <span class="mx-2">Name</span>
            <input v-model="newNodeName" placeholder="Name" />
          </div>

          <div class="col mb-4">
            <span class="mx-2">Title</span>
            <input v-model="newNodeTitle" placeholder="Title" />
          </div>

        </div>
      </div>

      <div class="row">
        <div class="col">
          <button @click="addNode" class="btn btn-primary float-right">
            {{ "Add Node" }}
          </button>
        </div>
      </div>
    </b-modal>

    <div class="container_margin">
      <div class="row">
        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-9 mt-2">
          <div class="card border shadow-sm p-3 rounded h-100">
            <div class="row">
              <div class="col">
                <h5 class="font-weight-bold text-blue">
                  {{ "Org Chart" }}
                </h5>
              </div>
            </div>

            <div class="row mt-2">
              <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div class="row">
                  <organization-chart :datasource="ds" :key="ocKey" :pan="false" :zoom="false" @node-click="selectNode"
                    @click.native="clickContainer($event)">
                    <template slot-scope="{ nodeData }">
                      <div :class="['node-box', nodeData.id == '1' ? 'parent' : '']">
                        <div class="node-title">{{ nodeData.name }}</div>
                        <div class="node-content">
                          <div>
                            <span>{{ nodeData.title }}</span>
                          </div>

                          <!-- Add a button to add a child node -->
                          <!-- <button v-b-modal.addNode @click="openModal(nodeData)">+</button> -->

                        </div>
                      </div>
                    </template>
                  </organization-chart>
                </div>


              </div>
            </div>
          </div>
        </div>

        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3 mt-2">
          <div class="card border shadow-sm p-4 rounded h-80">
            <div class="row card border shadow-sm p-3 rounded">
              <h5 class="text-blue font-weight-bold">{{ "Selected Node" }}</h5>

              <div class="row mt-1">
                <div class="col-12">
                  <div class="row mt-1">
                    <div class="col-12" v-for="(val, key) in selectedNode" :key="key">
                      <p>{{ "Employee Name" }} - {{ val.name }}</p>
                      <p>{{ "Employee Title" }} - {{ val.title }}</p>
                      <hr>
                    </div>
                    <!-- <div class="col-4">
                      <p>Hello</p>
                    </div>

                    <div class="col">
                      <b class="text-blue"> {{ "You" }} </b>
                    </div> -->
                  </div>
                </div>
              </div>
            </div>

            <div class="row card border shadow-sm p-3 rounded mt-3">
              <h5 class="text-blue font-weight-bold">{{ "New Node" }}</h5>
              <div class="col mb-3">
                <span>Name</span>
                <input v-model="newNodeName" placeholder="Name" class="mx-2" />
              </div>

              <div class="col mb-4">
                <span>Title</span>
                <input v-model="newNodeTitle" placeholder="Title" class="mx-2" />
              </div>
            </div>

            <div class="row mt-2" style="position: absolute; bottom: 10px; right: 20px;">
              <div class="col-1"></div>
              <div class="col align-self-center">
                <button @click="addNode" class="btn btn-primary sm">
                  {{ "Add Node" }}
                </button>
              </div>
            </div>

          </div>
        </div>


        <button v-if="upgrading" class="btn btn-warning-outlined">
          {{ completedPerCentage.toFixed(2) }} %
          <b-spinner label="Spinning" class="spinner"></b-spinner>
        </button>
        <button v-else class="btn btn-primary">
          {{ "Open" }}
        </button>

      </div>
    </div>



    <!-- <div class="row">
      <div class="col">
        <h3>New Node:</h3>
        <input v-model="newNodeName" placeholder="name">
        <input v-model="newNodeTitle" placeholder="title">
      </div>
      <div class="btn-group">
        <button @click="addChildNode">Add Child Node</button>
        <button @click="addSiblingNode">Add Sibing Node</button>
        <button @click="addRootNode">Add Root Node</button>
        <button @click="removeNodes">Remove Nodes</button>
        <button @click="updateNode">Update Node</button>
      </div>
    </div> -->

  </div>
</template>



<script>
/* eslint-disable */
import JSONDigger from "json-digger";

import OrganizationChart from "vue-organization-chart";
import "vue-organization-chart/dist/orgchart.css";
import moment from 'moment';


export default {
  components: { OrganizationChart },
  data() {
    return {

      //spinner
      upgrading: true,
      completedPerCentage:90,


      ocKey: 0,
      showModal: false,
      newNodeName: "",
      newNodeTitle: "",
      selectedNode: {},
      // singleNode: {},

      id: 9,

      ds: {
        id: "1",
        name: "Lao Lao",
        title: "general manager",
        children: [
          { id: "2", name: "Bo Miao", title: "department manager" },
          {
            id: "3",
            name: "Su Miao",
            title: "department manager",
            children: [
              { id: "4", name: "Tie Hua", title: "senior engineer" },
              {
                id: "5",
                name: "Hei Hei",
                title: "senior engineer",
                children: [
                  { id: "6", name: "Pang Pang", title: "engineer" },
                  { id: "7", name: "Xiang Xiang", title: "UE engineer" },
                ],
              },
            ],
          },
          { id: "8", name: "Hong Miao", title: "department manager" },
          { id: "9", name: "Chun Miao", title: "department manager" },
        ],
      },
    };
  },

  mounted() {
    this.$el
      .querySelector(".orgchart")
      .setAttribute("style", "background-image: none;");
  },

  created() {
    this.dsDigger = new JSONDigger(this.ds, "id", "children");

    var me = this;
    function loop() {

    
      setTimeout(() => {
        console.log(me.upgrading)

    

        const setTime = "Fri Oct 13 2023 14:49:07 GMT+0800 (Malaysia Time)"

        const createdTime = moment(new Date(setTime).getTime());

        const duration = 180000;
        // const increment = (targetValue - this.completedPerCentage) / (duration * 1000 / 100); // Increment every 100ms

        const currentTime = moment(new Date().getTime());

        const elapsedTime = (currentTime.diff(createdTime));// Calculate time elapsed in seconds

        console.log(elapsedTime, duration)

        if (elapsedTime < duration) {
          if (me.completedPerCentage < 100) {
            me.completedPerCentage = (elapsedTime/duration)*100
            me.upgrading = true;
            loop();
          }
          else {
            me.upgrading = false;
          }
        } else {
          if (me.completedPerCentage < 100) {
            me.completedPerCentage = 90
            me.upgrading = true;
            loop();
          }
          me.upgrading = false;
        }
      }, 5000);
    }

    loop()





  },

  methods: {

    // startUpgrade() {
    //   this.upgrading = true;
    //   this.upgradePercentage(0, 100, 180); // 180 seconds (3 minutes)
    // },



    forceRerender() {
      console.log("render");
      this.ocKey += 1;

      this.$el
        .querySelector(".orgchart")
        .setAttribute("style", "background-image: none;");
    },

    // selectNode(nodeData) {
    //   this.selectedNode = nodeData;
    // },

    selectNode(nodeData) {
      // console.log(this.selectedNode);
      // if (this.selectedNode.length == null ) {
      // this.selectedNode = nodeData; 
      // console.log(this.selectedNode);
      let singleNode = Object.assign({}, nodeData);
      delete singleNode.children;
      $("#" + singleNode.id).toggleClass("selected");
      if ($("#" + singleNode.id).is(".selected")) {
        this.$set(this.selectedNode, singleNode.id, singleNode);
      } else {
        this.$delete(this.selectedNode, singleNode.id);
      }
      // } else {
      //   console.log(this.selectedNode.length);
      //   this.$toast.error("Choose 1 employee only!");
      // }
    },

    clickContainer(event) {
      $(".node.selected").removeClass("selected");
      if (!$(event.target).closest(".node").length) {
        this.selectedNode = {};
      }
    },

    // async addChildNode() {
    //   console.log("addChild", this.selectedNode.id);

    //   await this.dsDigger.addChildren(this.selectedNode.id, {
    //     id: this.id + 1,
    //     name: this.newNodeName,
    //     title: this.newNodeTitle,
    //   });

    //   (this.id = this.id + 1), console.log(this.ds);

    //   this.forceRerender();
    // },

    // openModal(nodeData) {
    //   console.log("here");
    //   this.selectedNode = nodeData;

    //   console.log(this.selectedNode);
    // },

    addNode() {

      console.log("addChild", this.singleNode);

      const newChild = {
        id: this.id + 1,
        name: this.newNodeName,
        title: this.newNodeTitle,
      };

      this.dsDigger.addChildren(this.singleNode.id, newChild);

      this.id = this.id + 1;
      console.log(this.ds);

      this.forceRerender();

    },

    // async addSiblingNode() {
    //   await this.dsDigger.addSiblings(this.selectedNode.id, {
    //     id: this.id + 1,
    //     name: this.newNodeName,
    //     title: this.newNodeTitle,
    //   });

    //   this.id = this.id + 1;
    // },
    // async addRootNode() {
    //   await this.dsDigger.addRoot({
    //     id: this.id + 1,
    //     name: this.newNodeName,
    //     title: this.newNodeTitle,
    //   });

    //   this.id = this.id + 1;
    // },
    // async removeNodes() {
    //   console.log(this.selectedNode);
    //   await this.dsDigger.removeNodes(this.selectedNode);
    //   this.forceRerender();
    //   this.selectedNode = {};
    // },
    // async updateNode() {
    //   await this.dsDigger.updateNode({
    //     id: this.selectedNode.id,
    //     name: this.newNodeName,
    //     title: this.newNodeTitle,
    //   });
    // },
  },

};
</script>


<style scoped>
.container_margin {
  padding: 1rem 2rem;
}

.orgchart-container {
  height: auto;
  border: none;
  background-image: unset !important;
}

.orgchart {
  background: #000;
  background-image: unset !important;
}

.node-box {
  border: 1px solid rgb(13, 2, 63);
  position: relative;
}

.node-box.parent::before {
  content: "";
  display: none;
}

.node-title {
  padding: 0.5vw 0.1vw;
  background: #094890;
  color: white;
}

.node-content {
  padding: 0.5vw 0.2vw;
  color: #000;
}

/* .node-box::before {
  content: "▼";
  position: absolute;
  top: -16px;
  left: 50%;
  color: #06c4de;
  margin-left: -8px;
} */
/* tr {
  border-color: black !important;
}
.lines {
  border-color: black !important;
} */


/* Variables */

:root {
  --white: #eee9dc;
  --bg: #2e6ba7;

  --horizontal-gutter: 100px;
  --border-radius: 10px;

  --entry-min-height: 60px;

  --label-width: 150px;
  --label-height: 30px;
  --label-border-radius: 5px;
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  min-width: 1200px;
  margin: 0;
  padding: 50px;
  color: var(--white);
  font: 16px Verdana, sans-serif;
  background: var(--bg);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#wrapper {
  position: relative;
}

.branch {
  position: relative;
  margin-left: calc(var(--horizontal-gutter) + var(--label-width));
}

.branch:before {
  content: "";
  width: calc(var(--horizontal-gutter) / 2);
  border-top: 2px solid var(--white);
  position: absolute;
  left: calc(-1 * var(--horizontal-gutter));
  top: 50%;
  margin-top: 1px;
}

.entry {
  position: relative;
  min-height: var(--entry-min-height);
}

.entry:before {
  content: "";
  height: 100%;
  border-left: 2px solid var(--white);
  position: absolute;
  left: calc(-1 * var(--horizontal-gutter) / 2);
}

.entry:after {
  content: "";
  width: calc(var(--horizontal-gutter) / 2);
  border-top: 2px solid var(--white);
  position: absolute;
  left: calc(-1 * var(--horizontal-gutter) / 2);
  top: 50%;
  margin-top: 1px;
}

.entry:first-child:before {
  width: var(--border-radius);
  height: 50%;
  top: 50%;
  margin-top: 2px;
  border-radius: var(--border-radius) 0 0 0;
}

.entry:first-child:after {
  height: var(--border-radius);
  border-radius: var(--border-radius) 0 0 0;
}

.entry:last-child:before {
  width: var(--border-radius);
  height: 50%;
  border-radius: 0 0 0 var(--border-radius);
}

.entry:last-child:after {
  height: var(--border-radius);
  border-top: none;
  border-bottom: 2px solid var(--white);
  border-radius: 0 0 0 var(--border-radius);
  margin-top: calc(-1 * var(--border-radius) + 1px);
}

.entry.sole:before {
  display: none;
}

.entry.sole:after {
  width: calc(var(--horizontal-gutter) / 2);
  height: 0;
  margin-top: 1px;
  border-radius: 0;
}

.label {
  display: block;
  min-width: var(--label-width);
  padding: 5px 10px;
  line-height: calc(var(--label-height) - 5px * 2);
  text-align: center;
  border: 2px solid var(--white);
  border-radius: var(--label-border-radius);
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: calc(-1 * var(--label-height) / 2);
}
</style>
